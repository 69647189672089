import { SetStateAction, useEffect, useState} from 'react'
import {
  onSnapshot,
  query,
  collection,
  where
} from "firebase/firestore"

import {database} from "../config/firebase"

const useDatabase = (content: string, id: string) => {

  const [data, setData] = useState()
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    const q = query(collection(database, content), where('__name__', '==', id))
    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      let queryResults: any

      querySnapshot.forEach((doc) => {
        queryResults = {
          id: doc.id,
          ...doc.data()
        }
      })
      setData(queryResults)
      setLoaded(true)
    });
    return () => unsubscribe()

  }, [content] )

  return [data, isLoaded]

}

export default useDatabase