//@ts-nocheck
import { ShowStoryInterface } from './ShowStory.interface'
import { useParams } from "react-router-dom"
import useDatabase from "../../../hooks/useDatabase"
import { useEffect, useState } from 'react'
import {formatDate} from "../../../lib";

const ShowStory = ({testID}:ShowStoryInterface) => {

    const { id } = useParams()
    const [data, isLoaded] = useDatabase("stories", id as string)

    const [dt, setDt] = useState<string>("")

    useEffect(() => {
        if(isLoaded) {
            const date = new Date(data?.date.seconds * 1000)
            setDt(formatDate(date))
        }
    }, [isLoaded, data])

    return(
        <div data-testid={ testID }
             className={ `ShowStory` }>

            <div className={`ShowStoryInfo`}>
                <div className={`ShowStoryInfo__title__icon`}
                        style={{backgroundColor: data?.color}}>{data?.icon}</div>
                <div>
                    <p className={`ShowStoryInfo__title`} style={{color: data?.color}}>{data?.title.trim()}</p>
                    <p className={`ShowStoryInfo__date`}>{dt}</p>
                </div>
            </div>

            <div className={`ShowStory__images`}>
                {
                    data?.images.map( (item: { title: string; description: string; image: string }, index) => {
                                 const r = index % 2 === 0 ?  "rotate_plus" : "rotate_minus"
                                 return(
                                   <div className={`ShowStory__images__item ${r}}`}>
                                       <img
                                         src={"data:image/jpeg;base64," + item.image}
                                         alt={item.title}/>
                                       { (item.title || item.description) &&
                                         <div style={{padding: 5}}>
                                                <h5>{item.title}</h5>
                                                <p>{item.description}</p>
                                         </div>
                                       }
                                   </div>
                                 )
                    })
                }
            </div>


        </div>
    )

}

export default ShowStory
