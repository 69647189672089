import { initializeApp } from "firebase/app"
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyDGmQqgwYC5N8jrdAibqLtNXYENHHSMzcw",
  authDomain: "ebb-advies.firebaseapp.com",
  projectId: "ebb-advies",
  storageBucket: "ebb-advies.appspot.com",
  messagingSenderId: "584238258280",
  appId: "1:584238258280:web:fd02c9e352201d562883dd"
}

const app = initializeApp(firebaseConfig)
const database = getFirestore(app)

export { app, database }