// import { useState, useEffect, useContext } from 'react'
import { LogoInterface } from './Logo.interface'
import LogoImage from '../../../resources/images/logo.png'

const Logo = ({testID }:LogoInterface) => {


    return(
        <div data-testid={ testID }
                className={ `Logo` }>
            <img src={LogoImage} alt={'Ebb'}/>
        </div>
    )

}

export default Logo
