// import { useState, useEffect, useContext } from 'react'
import { HomeInterface } from './Home.interface'

const Home = ({testID }:HomeInterface) => {

    return(
        <div data-testid={ testID } className={ `Home` }>
        </div>
    )

}

export default Home
