import React from 'react'
import {Logo} from './components/atoms'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import {
  Home,
  NotFound,
  ShowStory
} from "./components/pages";
import {Menu} from "./components/templates";



const App = () => {


  return(
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Menu /> }>
            <Route index element={<Home />} />
            <Route path="/show/:id" element={<ShowStory />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
  )
}


export default App;
