// import { useState, useEffect, useContext } from 'react'
import { NotFoundInterface } from './NotFound.interface'

/// TODO: update interface/arguments for NotFound
const NotFound = ({testID, style, type, size, props }:NotFoundInterface) => {

    return(
        <div data-testid={ testID }
             data-object-type={ type ?? ""}
             className={ `NotFound` }>
        </div>
    )

}

export default NotFound
