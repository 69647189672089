import { MenuInterface } from './Menu.interface'
import { Outlet } from "react-router-dom";
import {Logo} from "../../atoms";

const Menu = ({testID }:MenuInterface) => {

    return(
        <div data-testid={ testID } className={ `Menu` }>
            <Logo />
            <Outlet />
        </div>
    )

}

export default Menu
